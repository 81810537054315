<template> 
    <v-layout justify-center class="py-2" fill-height>  
        <mbs-page-spinner v-if="!Joined_SaleOrder" />  
        <span v-else>
            <span v-if="document_type=='docket'">
                <mbs-epson-display-temp1  v-for="(request,index) in DAY_SHIFT_PRINT_REQUEST"
                    :key="index"
                    :show="true"
                    :loading="inputLoading"
                    :print_request="request"/> 
            </span>
            <span v-else>
                <mbs-epson-display-temp1  
                    :show="true"
                    :loading="inputLoading"
                    :print_request="DAY_SHIFT_PRINT_REQUEST"/>  
            </span>

        </span>
    </v-layout> 
</template>

<script>
    import DATA from "../../../plugins/DATA"
    import {mapState } from "vuex"
    let PAGE_NAME  = 'DAY-SHIFT-TEMPLATE-1'
    export default {
        props:['data','merge_doc_type','report_type','document_type','title','sales_type','closing_cash'], 
        data(){
            return{
                show:true,
                DATA:DATA,
                printSVG:null,
                inputLoading:true,
                DAY_SHIFT_PRINT_REQUEST:null,
            } 
        }, 
        mounted(){ 
            this.EPSON_SALE_ORDER_REQUEST({
                merge_doc_type:this.merge_doc_type,
                report_type:this.report_type,
                document_type:this.document_type,
                sale_order:this.Joined_SaleOrder,
            }) 
        },
        computed:{
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],  
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            }), 
             
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            SaleOrder(){ 
                let data = this.data 
                return  data
            },
            Joined_SaleOrder(){ 
                let sale = this.SaleOrder  
                let document_type = this.document_type  
                let join = null
                if (document_type=="payment") {
                    join = sale
                }else{
                    join = this.MBS.actions.JOIN_SALE_ORDER(sale)  
                }
                return join
            }, 
            Company(){ 
                let item = this.Joined_SaleOrder
                return item?item.company:null
            },  
            
             
        },
        methods:{
            async EPSON_SALE_ORDER_REQUEST(payload){ 
                try {   
                    console.log(payload,'payload.....');
                    this.inputLoading = true
                    let print_order = null
                    if (payload?.document_type=="payment") {
                        print_order = await this.MBS.actions.EPSON_SALE_PAYMENT_REQUEST({
                            ...payload,
                            print_title:this.title,
                            printer:{
                                paper_size:42
                            }
                        })  
                    } else if (payload?.document_type=="docket"){
                        console.log('Print docket-------------------------');
                        print_order = await this.MBS.actions.EPSON_DOCKET_REQUEST({
                            ...payload,
                            print_title:this.title,
                            printer:{
                                paper_size:38
                            }
                        }) 

                        console.log(print_order,'print_order.........///');
                        
                    } else {
                        print_order = await this.MBS.actions.EPSON_SALE_ORDER_REQUEST({
                            ...payload,
                            print_title:this.title,
                            printer:{
                                paper_size:38
                            }
                        }) 
                    }
                    this.DAY_SHIFT_PRINT_REQUEST = print_order?print_order.data:null
                    console.log(this.DAY_SHIFT_PRINT_REQUEST,'DAY_SHIFT_PRINT_REQUEST......**..');
                    this.inputLoading = false 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EPSON_SALE_ORDER_REQUEST',
                        page:PAGE_NAME, 
                    }) 
                    return 
                }   
            },    
        },
        watch:{
            mbs_dialog(value){ 
            },  
            Joined_SaleOrder(value){ 
                this.EPSON_SALE_ORDER_REQUEST({
                    merge_doc_type:this.merge_doc_type,
                    report_type:this.report_type,
                    document_type:this.document_type,
                    sale_order:value,
                }) 
            },   
        } 
    }
</script>
